/* Main container */
.pre-event-table-container {
  min-height: calc(100vh - 70px);
  margin-left: 250px;
  padding: 20px;
  padding-top: 90px;
  background: #ffffff;
  transition: all 0.3s ease;
  width: calc(100% - 250px);
}

/* Header styles */
.pre-event-table-header {
  background-color: #ffe092;
  padding: 20px;
  margin: 0 0 20px 0;
  border-radius: 0;
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
}

.pre-event-table-breadcrumb {
  font-size: var(--text-sm);
  color: #000;
  display: flex;
  align-items: center;
  gap: 8px;
}

.pre-event-table-breadcrumb .separator {
  margin: 0 8px;
}

/* Content area */
.pre-event-table-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.pre-event-table-content h2 {
  font-family: 'Public Sans', sans-serif;
  color: #333;
  margin-bottom: 10px;
}

.pre-event-table-content p {
  font-family: 'Public Sans', sans-serif;
  color: #000000;
  margin-bottom: 20px;
}

/* Grid layout for platforms */
.pre-event-table-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-bottom: 30px;
}

.pre-event-platform-section {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
  width: 100%;
}

.platform-heading {
  color: #ff0000;
  font-size: 18px;
  font-weight: 600;
  padding: 16px;
  margin-bottom: 0;
}

/* Table styles */
.pre-event-table-wrapper {
  overflow-x: auto;
}

.pre-event-table-wrapper table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: white;
}

.pre-event-table-wrapper thead {
  background: #f8f9fa;
}

.pre-event-table-wrapper th {
  padding: 12px;
  font-weight: 500;
  color: #666;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.pre-event-table-wrapper td {
  padding: 12px;
  border-bottom: 1px solid #eee;
  vertical-align: middle;
}

/* Fixed column widths */
.pre-event-table-wrapper th:nth-child(1),
.pre-event-table-wrapper td:nth-child(1) {
  width: 35%;
  padding-left: 16px;
}

.pre-event-table-wrapper th:nth-child(2),
.pre-event-table-wrapper td:nth-child(2) {
  width: 45%;
}

.pre-event-table-wrapper th:nth-child(3),
.pre-event-table-wrapper td:nth-child(3) {
  width: 20%;
  text-align: center;
}

/* Input styles */
.pre-event-input-number {
  width: 90%;
  height: 36px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f8f9fa;
}

.pre-event-checkbox {
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
}

/* Platform colors */
td[data-platform^="google"] { color: #4285f4; }
td[data-platform^="internal"] { color: #0066cc; }
td[data-platform^="influencers"] { color: #833AB4; }
td[data-platform="newspaper"] { color: #ff3300; }
td[data-platform="billboard"] { color: #00cc66; }
td[data-platform^="metro"] { color: #1DB954; }
td[data-platform^="taxi"] { color: #ff6600; }
td[data-platform^="regional"] { color: #ff3300; }

/* Summary section */
.pre-event-summary {
  margin: 24px 0;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pre-event-summary strong {
  color: #333;
}

/* Action buttons */
.pre-event-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.pre-event-btn-back,
.pre-event-btn-next {
  padding: 12px 32px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  height: 40px;
}

.pre-event-btn-back {
  background: white;
  border: 1px solid #ddd;
  color: #333;
}

.pre-event-btn-next {
  background: #ffc107;
  border: none;
  color: black;
}

/* Responsive styles */
@media screen and (max-width: 1200px) {
  .pre-event-table-container {
    margin-left: 200px;
    width: calc(100% - 200px);
  }
}

@media screen and (max-width: 992px) {
  .pre-event-table-container {
    margin-left: 180px;
    width: calc(100% - 180px);
  }
  
  .pre-event-table-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

@media screen and (max-width: 768px) {
  .pre-event-table-container {
    margin-left: 0;
    width: 100%;
    padding: 15px;
    padding-top: 80px;
  }

  .pre-event-table-header {
    height: auto;
    padding: 15px;
  }

  .pre-event-table-content {
    padding: 0 10px;
  }

  .pre-event-summary {
    padding: 15px;
  }

  .pre-event-actions {
    flex-direction: row;
    gap: 15px;
  }

  .pre-event-btn-back,
  .pre-event-btn-next {
    flex: 1;
  }

  .pre-event-table-wrapper {
    overflow-x: auto;
    margin: 0 -10px;
    padding: 0 10px;
  }
}