/* Main container */
.pre-event-details-container {
  min-height: calc(100vh - 70px);
  margin-left: 250px;
  padding: 20px;
  padding-top: 90px;
  background: #ffffff;
  transition: all 0.3s ease;
  width: calc(100% - 250px);
}

/* Header styles */
.pre-event-details-header {
  background-color: #ffe092;
  padding: 20px;
  margin: 0 0 20px 0;
  border-radius: 0;
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
}

.pre-event-details-breadcrumb {
  font-size: var(--text-sm);
  color: #000;
  display: flex;
  align-items: center;
  gap: 8px;
}

.pre-event-details-breadcrumb .separator {
  margin: 0 8px;
}

/* Content area */
.pre-event-details-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.pre-event-details-content h2 {
  font-size: 24px;
  margin-bottom: 30px;
  color: #333;
}

/* Charts grid */
.pre-event-charts-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-bottom: 40px;
}

.pre-event-chart-card {
  background: #fff;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
}

.pre-event-chart-card h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.pre-event-total {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  margin: 5px 0;
}

.pre-event-subheader {
  font-size: 14px;
  color: #666;
}

/* Update existing styles */
.pre-event-chart-card {
  background: #fff;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
}

.pre-event-chart-wrapper {
  aspect-ratio: 1;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Action buttons */
.pre-event-details-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.pre-event-btn-back,
.pre-event-btn-save {
  padding: 12px 32px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  height: 40px;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.pre-event-btn-back {
  background: white;
  border: 1px solid #ddd;
  color: #333;
}

.pre-event-btn-back:hover {
  background: #f8f9fa;
}

.pre-event-btn-save {
  background: #ffc107;
  border: none;
  color: black;
}

.pre-event-btn-save:hover {
  background: #ffca2c;
}

/* Responsive styles */
@media screen and (max-width: 1200px) {
  .pre-event-details-container {
    margin-left: 200px;
    width: calc(100% - 200px);
  }

    .pre-event-charts-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .pre-event-chart-wrapper {
    max-width: 350px;
  }
}

@media screen and (max-width: 992px) {
  .pre-event-details-container {
    margin-left: 180px;
    width: calc(100% - 180px);
  }
  
  .pre-event-charts-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .pre-event-chart-wrapper {
    max-width: 350px;
  }
}

@media screen and (max-width: 768px) {
  .pre-event-details-container {
    margin-left: 0;
    width: 100%;
    padding: 15px;
    padding-top: 80px;
  }

  .pre-event-details-header {
    height: auto;
    padding: 15px;
  }

  .pre-event-details-content {
    padding: 0 10px;
  }

  .pre-event-chart-card {
    padding: 20px;
  }

  .pre-event-chart-wrapper {
    max-width: 300px;
  }

  .pre-event-details-actions {
    flex-direction: row;
    gap: 15px;
  }

  .pre-event-btn-back,
  .pre-event-btn-save {
    flex: 1;
    padding: 12px 20px;
  }
}