/* Top Banner */
.top-banner {
  background: #FDB813;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  z-index: 100;
}


.logo-area {
  display: flex;
  align-items: center;
}

.logo-area img {
  height: 35px;
  width: auto;
  object-fit: contain;
}

/* Mobile breadcrumb */
.mobile-breadcrumb {
  display: none; /* Hidden by default, shown on mobile */
  font-size: var(--text-base);
  font-weight: var(--font-semibold);
  color: var(--text-primary);
  text-align: center;
}

/* Sidebar */
.sidebar {
  width: 250px;
  height: calc(100vh - 70px);
  position: fixed;
  left: 0;
  top: 70px; /* Match top banner height */
  background: white;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  z-index: 90;
  transition: all 0.3s ease;
}

/* Navigation Menu */
.nav-menu {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.nav-item {
  width: 100%;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  background: none;
  border: none;
  font-size: var(--text-base);
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.2s;
  text-align: left;
}

.nav-item i {
  width: 20px;
  font-size: 18px;
}

.nav-item.active {
  background: #9747FF;
  color: white;
}

.nav-item:not(.active):hover {
  background: #f5f5f5;
}

/* Bottom Menu */
.bottom-menu {
  margin-top: auto;
  padding: 20px 0;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Mobile Menu Toggle Button */
.mobile-menu-toggle {
  display: none; /* Hidden by default, shown on mobile */
  background: none;
  border: none;
  color: #000;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  z-index: 101;
}

/* Overlay for mobile */
.sidebar-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 89;
  opacity: 0;
  transition: opacity 0.3s ease;
}

/* Responsive Breakpoints */
@media screen and (max-width: 1200px) {
  .sidebar {
    width: 200px;
  }
  
  .nav-item {
    padding: 10px 15px;
    font-size: var(--text-sm);
  }
}

@media screen and (max-width: 992px) {
  .sidebar {
    width: 180px;
  }
  
  .nav-item {
    padding: 10px 12px;
  }
  
  .nav-item i {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .top-banner {
    height: 60px;
    padding: 0 15px;
  }
  
  .mobile-menu-toggle {
    display: block;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .mobile-breadcrumb {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 60%;
  }
  
  .logo-area {
    position: absolute;
    left: 15px;
  }
  
  .logo-area img {
    height: 30px;
  }
  
  .sidebar {
    transform: translateX(-100%);
    top: 60px;
    height: calc(100vh - 60px);
    width: 250px;
  }
  
  .sidebar.active {
    transform: translateX(0);
  }
  
  .sidebar-overlay {
    display: block;
  }
  
  .sidebar.active + .sidebar-overlay {
    opacity: 1;
  }
  
  .nav-item {
    padding: 12px 20px;
    font-size: var(--text-base);
  }
  
  .nav-item i {
    font-size: 18px;
  }
}

@media screen and (max-width: 576px) {
  .top-banner {
    padding: 0 10px;
  }
  
  .mobile-menu-toggle {
    right: 10px;
  }
  
  .logo-area {
    left: 10px;
  }
  
  .logo-area img {
    height: 25px;
  }
  
  .mobile-breadcrumb {
    max-width: 50%;
    font-size: var(--text-sm);
  }
  
  .nav-item {
    padding: 10px 15px;
  }
}

/* Animation for mobile menu icon */
.mobile-menu-toggle i {
  transition: transform 0.3s ease;
}

.mobile-menu-toggle.active i {
  transform: rotate(180deg);
}

/* Active state styles */
.sidebar.active ~ .sidebar-overlay {
  opacity: 1;
  pointer-events: auto;
}

/* Ensure content doesn't scroll when sidebar is open on mobile */
body.sidebar-active {
  overflow: hidden;
}