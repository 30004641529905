/* typography.css */

/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;700&display=swap');

:root {
  /* Font families */
  --font-primary: 'Nunito Sans', sans-serif;
  --font-secondary: 'Public Sans', sans-serif;
  
  /* Font sizes */
  --text-xs: 0.75rem;    /* 12px */
  --text-sm: 0.875rem;   /* 14px */
  --text-base: 1rem;     /* 16px */
  --text-lg: 1.125rem;   /* 18px */
  --text-xl: 1.25rem;    /* 20px */
  --text-2xl: 1.5rem;    /* 24px */
  --text-3xl: 1.875rem;  /* 30px */
  --text-4xl: 2.25rem;   /* 36px */
  
  /* Font weights */
  --font-normal: 400;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;
  
  /* Line heights */
  --leading-none: 1;
  --leading-tight: 1.25;
  --leading-normal: 1.5;
  --leading-relaxed: 1.625;
  
  /* Colors for text */
  --text-primary: #333333;
  --text-secondary: #666666;
  --text-muted: #888888;
}

/* Reset default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Base styles */
body {
  font-family: var(--font-primary);
  font-size: var(--text-base);
  line-height: var(--leading-normal);
  color: var(--text-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Typography scale */
h1, .h1 {
  font-family: var(--font-primary);
  font-size: var(--text-3xl);
  font-weight: var(--font-bold);
  line-height: var(--leading-tight);
  margin-bottom: 1rem;
}

h2, .h2 {
  font-family: var(--font-primary);
  font-size: var(--text-2xl);
  font-weight: var(--font-semibold);
  line-height: var(--leading-tight);
  margin-bottom: 0.75rem;
}

h3, .h3 {
  font-family: var(--font-primary);
  font-size: var(--text-xl);
  font-weight: var(--font-semibold);
  line-height: var(--leading-tight);
  margin-bottom: 0.75rem;
}

h4, .h4 {
  font-family: var(--font-primary);
  font-size: var(--text-lg);
  font-weight: var(--font-medium);
  line-height: var(--leading-normal);
  margin-bottom: 0.5rem;
}

p {
  font-family: var(--font-primary);
  font-size: var(--text-base);
  line-height: var(--leading-relaxed);
  margin-bottom: 1rem;
}

/* Utility classes */
.text-sm {
  font-size: var(--text-sm);
}

.text-lg {
  font-size: var(--text-lg);
}

.font-medium {
  font-weight: var(--font-medium);
}

.font-semibold {
  font-weight: var(--font-semibold);
}

.text-muted {
  color: var(--text-muted);
}

.text-secondary {
  color: var(--text-secondary);
}

/* Optional: Add responsive typography */
@media (max-width: 768px) {
  :root {
    --text-3xl: 1.5rem;
    --text-2xl: 1.25rem;
    --text-xl: 1.125rem;
    --text-lg: 1rem;
    --text-base: 0.875rem;
  }
}