/* Popup Container */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.view-event-popup {
  background-color: white;
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
}

/* Header Styles */
.view-event-header {
  margin-bottom: 2rem;
}

.view-event-title {
  color: #333;
  font-family: 'Public Sans', sans-serif;
  font-size: 28px;
  margin-bottom: 1.5rem;
}

.view-event-details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
}

.view-event-details p {
  font-family: 'Public Sans', sans-serif;
  color: #666;
  margin: 0.5rem 0;
}

.view-event-details strong {
  color: #333;
}

/* Chart Section */
.view-event-charts {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin: 2rem 0;
}

.view-event-chart-container {
  flex: 1;
  min-width: 0;
  padding: 20px;
  background: #f8f8f8;
  border-radius: 12px;
  max-width: 450px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: auto;
}

.view-event-chart-title {
  text-align: center;
  font-family: 'Public Sans', sans-serif;
  font-size: 18px; /* Slightly reduced font size */
  margin-bottom: 1rem;
  color: #333;
}

.view-event-chart-wrapper {
  position: relative;
  width: 100%;
  aspect-ratio: 1; 
  margin-bottom: 1rem;
}

.view-event-chart-wrapper canvas {
  width: 100% !important;
  height: 100% !important;
  position: relative !important; 
}

/* Legend Styles */
.view-event-legend {
  max-height: 200px;
  overflow-y: auto;
  padding: 8px;
  margin-top: 1rem;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.view-event-legend-item {
  display: flex;
  align-items: center;
  padding: 4px 0;
  font-size: 13px;
  line-height: 1.4;
  word-break: break-word;
}


.view-event-legend::-webkit-scrollbar {
  width: 6px;
}

.view-event-legend::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.view-event-legend::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}


.view-event-legend-color {
  min-width: 12px;
  height: 12px;
  margin-right: 8px;
  border-radius: 3px;
  flex-shrink: 0;
}

/* Summary Section */
.view-event-summary {
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: #f8f8f8;
  border-radius: 8px;
}

.view-event-summary h3 {
  color: #333;
  margin-bottom: 1rem;
  font-family: 'Public Sans', sans-serif;
}

/* Button Container */
.view-event-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid #eee;
}

.view-event-button {
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.view-event-button-download {
  background-color: #4CAF50;
  color: white;
}

.view-event-button-edit {
  background-color: #FFB800;
  color: black;
}

.view-event-button-close {
  background-color: #f44336;
  color: white;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .view-event-popup {
    padding: 2rem;
  }

  .view-event-chart-container {
    max-width: 400px;
  }
  

}

@media (max-width: 992px) {
  .view-event-details {
    grid-template-columns: 1fr;
  }

  .view-event-charts {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .view-event-chart-container {
    width: 100%;
    max-width: 400px;
    padding: 16px;
  }
}

@media (max-width: 768px) {
  .view-event-popup {
    padding: 1.5rem;
    width: 95%;
  }

  .view-event-title {
    font-size: 24px;
  }

  .view-event-actions {
    flex-direction: column;
  }

  .view-event-button {
    width: 100%;
  }

  .view-event-chart-container {
    padding: 15px;
    max-width: 350px;
  }

  .view-event-chart-wrapper {
    max-height: none;
  }

  .view-event-chart-title {
    font-size: 16px;
  }

  .view-event-legend {
    font-size: 12px;
    max-height: 150px;
  }

  .view-event-legend-item {
    padding: 3px 0;
  }
}

.view-event-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100; /* Higher than regular popup */
}

.view-event-loading-container {
  background-color: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 90%;
  animation: slideIn 0.3s ease-out;
}

.view-event-loading-spinner {
  border: 4px solid #f3f4f6;
  border-top: 4px solid #4f46e5;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 0 auto 1.5rem;
  animation: spin 1s linear infinite;
}

.view-event-loading-message {
  color: #1f2937;
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  font-family: 'Public Sans', sans-serif;
}

.view-event-loading-submessage {
  color: #6b7280;
  font-size: 0.875rem;
  font-family: 'Public Sans', sans-serif;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Loading Progress Bar */
.view-event-loading-progress {
  width: 100%;
  height: 4px;
  background: #f3f4f6;
  border-radius: 2px;
  margin-top: 1.5rem;
  overflow: hidden;
  position: relative;
}

.view-event-loading-bar {
  position: absolute;
  width: 40%;
  height: 100%;
  background: #4f46e5;
  border-radius: 2px;
  animation: loading 2s infinite ease-in-out;
}

@keyframes loading {
  0% {
    left: -40%;
  }
  100% {
    left: 100%;
  }
}

/* Media Queries for Loading State */
@media (max-width: 768px) {
  .view-event-loading-container {
    width: 85%;
    padding: 1.5rem;
  }

  .view-event-loading-message {
    font-size: 1rem;
  }

  .view-event-loading-spinner {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 480px) {
  .view-event-loading-container {
    width: 90%;
    padding: 1.25rem;
  }

  .view-event-loading-message {
    font-size: 0.9375rem;
  }
}

@media (max-width: 480px) {
  .view-event-chart-container {
    max-width: 100%;
    padding: 12px;
  }

  .view-event-chart-wrapper {
    aspect-ratio: 1;
  }

  .view-event-legend {
    max-height: 120px;
  }

  .view-event-legend-item {
    font-size: 11px;
    padding: 2px 0;
  }

  .view-event-legend-color {
    min-width: 10px;
    height: 10px;
    margin-right: 6px;
  }
}


@media (max-width: 390px) {
  .view-event-chart-wrapper {
    width: 100%;
    aspect-ratio: 1;
    max-width: 300px; /* Limit maximum width on small screens */
    margin: 0 auto;
  }
}