.universal-header {
    width: 100%;
    height: 80px;
    background-color: #FFB800;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.header-left {
    display: flex;
    align-items: center;
}

.header-logo img {
    height: 65px; /* Adjusted to fit better in the header */
    width: 65px;
    margin-left: 15%;
}

.header-nav ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.header-nav ul li {
    margin: 0 15px;
}

.header-nav ul li a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s;
}

.header-nav ul li a:hover {
    color: #ffd700;
}

.header-right {
    display: flex;
    align-items: center;
}

.header-search {
    position: relative;
}

.header-search input {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
}

.header-search button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    padding: 0 10px;
}

.header-user {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.header-user img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.user-name {
    font-size: 16px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .universal-header {
        height: auto;
        flex-direction: column;
        align-items: flex-start;
    }

    .header-left, .header-right {
        margin-bottom: 10px;
    }

    .header-nav ul {
        flex-direction: column;
    }
}
