/* Main Layout */
.dashboard-page {
  min-height: 100vh;
  background: #ffffff;
  display: flex;
  position: relative;
}

.dashboard-content {
  flex: 1;
  margin-left: 250px;
  padding: 30px;
  margin-top: 70px;
  transition: all 0.3s ease;
}

/* Cards Grid */
.cards-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 30px;
}

.event-card {
  position: relative;
  height: 240px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.event-card:hover {
  transform: translateY(-4px);
}

.event-card::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  z-index: 1;
}

.card-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.card-content {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

.card-title {
  color: white;
  margin-bottom: 20px;
  font-size: var(--text-2xl);
  font-weight: var(--font-semibold);
  letter-spacing: 0.2px;
}

.card-content button {
  background: #FDB813;
  color: #000;
  border: none;
  padding: 12px 32px;
  border-radius: 6px;
  font-size: var(--text-base);
  font-weight: var(--font-medium);
  cursor: pointer;
  transition: all 0.2s ease;
}

.card-content button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Slider Section */
.featured-section {
  position: relative;
  height: 400px;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.slider-container {
  position: absolute;
  height: 100%;
  display: flex;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
}

.slider-slide {
  position: relative;
  min-width: 100%;
  height: 100%;
}

.slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background: #FDB813;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  transition: all 0.2s ease;
  opacity: 0.9;
}

.nav-btn:hover {
  opacity: 1;
  transform: translateY(-50%) scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.nav-btn.left { left: 20px; }
.nav-btn.right { right: 20px; }

.nav-btn i {
  color: #000;
  font-size: 18px;
}

/* Responsive Styles */
@media screen and (max-width: 1200px) {
  .dashboard-content {
    margin-left: 200px;
    padding: 25px;
  }

  .card-title {
    font-size: var(--text-xl);
  }

  .featured-section {
    height: 350px;
  }
}

@media screen and (max-width: 992px) {
  .dashboard-content {
    margin-left: 180px;
    padding: 20px;
  }

  .cards-grid {
    gap: 20px;
  }

  .event-card {
    height: 200px;
  }

  .card-content button {
    padding: 10px 24px;
    font-size: var(--text-sm);
  }

  .featured-section {
    height: 300px;
  }

  .nav-btn {
    width: 36px;
    height: 36px;
  }
}

@media screen and (max-width: 768px) {
  .dashboard-content {
    margin-left: 0;
    padding: 15px;
  }

  .cards-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .event-card {
    height: 180px;
  }

  .featured-section {
    height: 250px;
    margin-top: 20px;
  }

  .nav-btn {
    width: 32px;
    height: 32px;
  }

  .nav-btn i {
    font-size: 14px;
  }
}

@media screen and (max-width: 576px) {
  .dashboard-content {
    margin-top: 60px;
    padding: 12px;
  }

  .event-card {
    height: 160px;
  }

  .card-title {
    font-size: var(--text-lg);
    margin-bottom: 15px;
  }

  .card-content button {
    padding: 8px 20px;
    font-size: var(--text-sm);
  }

  .featured-section {
    height: 200px;
    margin-top: 16px;
  }

  .nav-btn {
    width: 28px;
    height: 28px;
  }

  .nav-btn.left { left: 10px; }
  .nav-btn.right { right: 10px; }
}

@media screen and (max-width: 360px) {
  .dashboard-content {
    padding: 10px;
  }

  .event-card {
    height: 140px;
  }

  .card-title {
    font-size: var(--text-base);
    margin-bottom: 12px;
  }

  .featured-section {
    height: 180px;
  }
}