
.app {
  color: #333;
  line-height: 1.6;
  font-size: 18px; 
  max-width: none; 
  margin: 0; 
  padding: 0 20px;
}

h1 {
  font-weight: 600px;
  font-size: 24px;
  font-family: Nunito Sans;
}

.import-text {
  color: #6366f1;
}