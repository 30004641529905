.page-container {
  display: flex;
  min-height: 100vh;
  background: #ffffff;
}

.content-wrapper {
  flex: 1;
  margin-left: 250px;
  padding: 20px;
  margin-top: 70px;
}

.event-list-content {
  max-width: 1400px;
  margin: 0 auto;
}

/* Update header section typography */
.event-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.title-section h1 {
  /* Use typography system variables */
  font-size: var(--text-2xl);
  font-weight: var(--font-semibold);
  color: #1A1A1A;
  margin-bottom: 4px;
}

.title-section p {
  color: var(--text-secondary);
  font-size: var(--text-sm);
}

.button-group {
  display: flex;
  gap: 12px;
}

.create-button, .compare-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  border-radius: 6px;
  font-weight: var(--font-medium);
  font-size: var(--text-sm);
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.create-button {
  background: #FDB813;
  color: #000;
}

.compare-button {
  background: #F3E5F5;
  color: #7B1FA2;
}

.create-button:hover, .compare-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

/* Quick Guide Banner */
.quick-guide {
  background: #F0F7FF;
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  font-size: var(--text-sm);
}

.quick-guide span {
  font-weight: var(--font-semibold);
  color: var(--text-primary);
}

.quick-guide i {
  color: #1976D2;
}

.close-guide {
  position: absolute;
  right: 16px;
  background: none;
  border: none;
  font-size: 18px;
  color: #666;
  cursor: pointer;
}

/* Table Styles */
.events-table {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  /* overflow-x: auto; Enable horizontal scroll if needed */
  width: 100%;
}


.table-header, .table-row {
  display: grid;
  grid-template-columns: 40px 1.5fr 1fr 1fr 1fr 2fr 80px;
  padding: 16px;
  align-items: center;
  min-width: 930px; /* Minimum width before scrolling */
  gap: 8px;
}
.table-header {
  background: #F8F9FA;
  font-weight: var(--font-semibold);
  color: var(--text-primary);
  font-size: var(--text-sm);
  border-bottom: 1px solid #E9ECEF;
}

.table-row {
  border-bottom: 1px solid #E9ECEF;
}

.table-row:last-child {
  border-bottom: none;
}

/* Table Cell Styles */
.select-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
}


.event-name-cell {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-right: 16px;
}


.event-title {
  font-weight: var(--font-medium);
  color: var(--text-primary);
  font-size: var(--text-base);
}

.event-date {
  font-size: var(--text-xs);
  color: var(--text-secondary);
}

.location-cell {
  display: flex;
  align-items: center;
  padding: 0 8px;
}

/* Status Badge */
.status-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  border-radius: 100px;
  font-size: var(--text-xs);
  font-weight: var(--font-medium);
  min-width: 100px; /* Fixed width for status badges */
  text-align: center;
}



.status-badge.completed {
  background: #E8F5E9;
  color: #2E7D32;
}

.status-badge.in-progress {
  background: #FFF3E0;
  color: #F57C00;
}

/* Phase Cell */
.phase-cell {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 8px;
}

/* Phase Items Typography */
.phase-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: var(--text-xs);
  color: var(--text-secondary);
}

.phase-circle {
  width: 16px;
  height: 16px;
  border: 2px solid #DDD;
  border-radius: 50%;
}

.phase-check {
  width: 16px;
  height: 16px;
  background: #4CAF50;
  border-radius: 50%;
  position: relative;
}

.phase-check::after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: white;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Action Buttons */
.action-buttons {
  display: flex;
  gap: 8px;
  align-items: center;
}

.action-button {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 6px;
  border: none;
  font-size: var(--text-sm);
  font-weight: var(--font-medium);
  cursor: pointer;
  transition: all 0.2s ease;
}


/* Button Variants */
.action-button.pre {
  background-color: #E3F2FD;
  color: #1976D2;
}

.action-button.post {
  background-color: #E8F5E9;
  color: #2E7D32;
}

.action-button.compare {
  background-color: #F3E5F5;
  color: #7B1FA2;
}

.action-button.view-strategy {
  background-color: #E3F2FD;
  color: #1976D2;
}

.action-button.add-results {
  background-color: #FFF3E0;
  color: #F57C00;
}

.action-button.add-pre {
  background-color: #F3E5F5;
  color: #7B1FA2;
}

.action-button.view-post {
  background-color: #E8F5E9;
  color: #2E7D32;
}

/* Button Icons */
.action-button.pre::before { content: "\f5b0"; }
.action-button.post::before { content: "\f5af"; }
.action-button.compare::before { content: "\f542"; }
.action-button.view-strategy::before { content: "\f080"; }
.action-button.add-results::before { content: "\f067"; }
.action-button.add-pre::before { content: "\f067"; }
.action-button.view-post::before { content: "\f200"; }

.action-button::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

/* Quick Guide Section */
.guide-section {
  margin-top: 30px;
  padding: 20px;
  background: #F8F9FA;
  border-radius: 12px;
  border: 1px solid #E9ECEF;
}

.guide-section h3 {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  color: var(--text-primary);
  font-size: var(--text-lg);
  font-weight: var(--font-semibold);
}

.guide-section h3::before {
  content: "\f05a";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #1976D2;
}

.guide-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}

.guide-card {
  background: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.actions-cell {
  display: flex;
  gap: 8px;
  padding-left: 8px;
}


.guide-items {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.guide-card h4 {
  font-size: var(--text-sm);
  font-weight: var(--font-semibold);
  margin-bottom: 12px;
  color: var(--text-primary);
}

.status-cell {
  display: flex;
  align-items: center;
}

.guide-item {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  font-size: var(--text-xs);
  color: var(--text-secondary);
}

.guide-item strong {
  color: var(--text-primary);
  font-weight: var(--font-medium);
}
/* Responsive Styles */
@media screen and (max-width: 1200px) {
  .content-wrapper {
    padding: 15px;
  }
  
  .guide-grid {
    gap: 16px;
  }

  .table-header, .table-row {
    min-width: 850px;
  }
}

@media screen and (max-width: 992px) {
  .content-wrapper {
    margin-left: 200px;
  }

  .event-list-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .button-group {
    width: 100%;
  }

  .create-button, .compare-button {
    flex: 1;
  }

  .events-table {
    overflow-x: auto;
  }
  
  .table-header, .table-row {
    min-width: 768px;
  }
}

@media screen and (max-width: 768px) {
  .content-wrapper {
    margin-left: 0;
  }

  .table-header, .table-row {
    grid-template-columns: 60px minmax(150px, 1.5fr) minmax(120px, 1fr) minmax(100px, 1fr);
    min-width: 600px;
  }

  .phase-cell {
    display: none;
  }

  .actions-cell {
    grid-column: 1 / -1;
    padding: 12px 0 0 0;
    justify-content: center;
  }

  .guide-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .action-buttons {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}


.delete-cell {
  display: flex;
  align-items: center;
}

.delete-event-button {
  background: none;
  border: none;
  color: #dc3545;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  transition: all 0.2s;
}

.delete-event-button:hover {
  background: #ffebee;
}

/* Update responsive styles */
@media screen and (max-width: 768px) {
  .table-header, .table-row {
    grid-template-columns: 60px minmax(150px, 1.5fr) minmax(120px, 1fr) minmax(100px, 1fr) 80px;
  }
}
@media screen and (max-width: 576px) {
  .table-header, .table-row {
    grid-template-columns: 40px minmax(120px, 1.5fr) minmax(100px, 1fr);
    min-width: 400px;
  }

  .status-cell {
    display: none;
  }

  .action-buttons {
    justify-content: center;
    width: 100%;
  }

  .event-list-header {
    margin-bottom: 16px;
  }

  .button-group {
    flex-direction: column;
  }

  .action-button {
    padding: 6px 12px;
    font-size: 13px;
  }
}

/* Loading States */
.loading-skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 4px;
}

@keyframes loading {
  0% { background-position: 200% 0; }
  100% { background-position: -200% 0; }
}