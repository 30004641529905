/* Main container */
.event-details {
  min-height: calc(100vh - 70px);
  margin-left: 250px;
  padding: 20px;
  padding-top: 90px;
  background: #ffffff;
  transition: all 0.3s ease;
  width: calc(100% - 250px);
  font-family: 'Public Sans', sans-serif;
}

/* Header/Breadcrumb */
.breadcrumb {
  background-color: #ffe092;
  padding: 20px;
  margin: 0 0 20px 0;
  border-radius: 0;
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  font-size: var(--text-sm);
}

.breadcrumb .separator {
  margin: 0 8px;
  color: #666;
}

/* Content container */
.event-details-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Budget Summary */
.budget-summary {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin: 30px 0;
}

.online-budget,
.offline-budget {
  background: #fff;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.budget-amount {
  font-size: 32px;
  font-weight: 600;
  margin: 8px 0;
  color: #333;
}

.budget-label {
  color: #666;
  font-size: 14px;
}

/* Charts Section */
.chart-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin: 30px 0;
}

.chart-container {
  background: white;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
  height: 400px; /* Fixed height */
  width: 100%;
  display: flex;
  flex-direction: column;
}

.chart-container h3 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  color: #333;
}

.chart-inner-container {
  flex: 1;
  position: relative;
  width: 100%;
  max-height: calc(100% - 50px); /* Account for heading */
}

.metrics-overview {
  padding: 20px;
}

.metrics-overview h2 {
  margin-bottom: 8px;
}

.metrics-overview p {
  color: #666;
  margin-bottom: 20px;
}

.metrics-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.metric-field {
  flex: 1;
  min-width: 0; /* Prevents flex items from overflowing */
}

.metric-field label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
}

.metric-field input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.required {
  color: red;
  margin-left: 4px;
}

/* Update metrics input layout */
.metrics-inputs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}

.metric-field {
  width: 100%;
}

.metrics-overview label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  white-space: normal; /* Allow text to wrap */
}

.metrics-overview input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Additional responsive adjustments */
@media screen and (max-width: 768px) {
  .metrics-inputs {
    grid-template-columns: 1fr;
    padding: 0;
  }
}

/* Add box-sizing to all elements */
*, *::before, *::after {
  box-sizing: border-box;
}

.metrics-label{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px; /* Increased gap for better spacing */
  margin-bottom: 25px;

}
/* Notes Section */
.notes-section {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  margin: 30px 0;
}

.notes-section textarea {
  width: 100%;
  min-height: 120px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-family: 'Public Sans', sans-serif;
  resize: vertical;
  background-color: #f7f7f7;
}

/* Action Buttons */
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.back-button,
.plan-next-event-button {
  padding: 10px 30px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  height: 40px;
  font-size: 14px;
  background-color: #FFB800;
  color: #000;
  border: none;
  transition: background-color 0.2s ease;
}

.back-button:hover,
.plan-next-event-button:hover {
  background-color: #FF8C00;
}

.plan-next-event-button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
  color: #666;
}

/* Responsive Breakpoints */
@media screen and (max-width: 1200px) {
  .event-details {
    margin-left: 200px;
    width: calc(100% - 200px);
  }
}

@media screen and (max-width: 992px) {
  .event-details {
    margin-left: 180px;
    width: calc(100% - 180px);
  }
  
  .budget-summary,
  .chart-wrapper,
  .metrics-inputs {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .chart-container {
    max-width: 500px;
    margin: 0 auto;
    height: 350px;
  }
}

@media screen and (max-width: 768px) {
  .event-details {
    margin-left: 0;
    width: 100%;
    padding: 15px;
    padding-top: 80px;
  }

  .breadcrumb {
    height: auto;
    padding: 15px;
  }

  .metrics-row {
    flex-direction: column;
    gap: 16px;
  }

  .budget-amount {
    font-size: 24px;
  }

  .chart-container {
    padding: 20px;
    max-width: 100%;
    height: 300px;
  }

  .metrics-inputs {
    grid-template-columns: 1fr;
  }

  .button-container {
    flex-direction: column;
    gap: 15px;
  }

  .back-button,
  .plan-next-event-button {
    width: 100%;
  }
}
