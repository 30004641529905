.notes-section {
    margin-top: 2rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    padding: 1.5rem;
  }
  
  .notes-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .notes-header h3 {
    margin: 0;
    font-size: 18px;
    color: #333;
  }
  
  .button-group {
    display: flex;
    gap: 8px;
  }
  
  .edit-button,
  .save-button,
  .cancel-button {
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    border: none;
  }
  
  .edit-button {
    background: #f8f9fa;
    color: #333;
    border: 1px solid #ddd;
  }
  
  .save-button {
    background: #ffc107;
    color: black;
  }
  
  .cancel-button {
    background: #f8f9fa;
    color: #333;
    border: 1px solid #ddd;
  }
  
  .notes-textarea {
    width: 100%;
    min-height: 200px;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.5;
  }
  
  .notes-textarea:focus {
    outline: none;
    border-color: #ffc107;
    box-shadow: 0 0 0 2px rgba(255, 193, 7, 0.2);
  }
  
  .notes-content {
    min-height: 200px;
    padding: 1rem;
    background: #f8f9fa;
    border-radius: 4px;
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 1.5;
  }
  
  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }