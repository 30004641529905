.event-selection-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .event-selection-popup {
    background: white;
    border-radius: 12px;
    padding: 24px;
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
  }
  
  .popup-header {
    margin-bottom: 24px;
    position: relative;
  }
  
  .popup-header h2 {
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 8px 0;
  }
  
  .popup-header p {
    color: #666;
    margin: 0;
  }
  
  .close-btn {
    position: absolute;
    top: -12px;
    right: -12px;
    background: #dc3545;
    color: white;
    border: none;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }
  
  .event-section {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .section-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .icon-wrapper {
    width: 48px;
    height: 48px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
  }
  
  .icon-wrapper.blue {
    background: #e6f3ff;
    color: #0066ff;
  }
  
  .icon-wrapper.green {
    background: #e6fff0;
    color: #00cc66;
  }
  
  .icon-wrapper i {
    font-size: 24px;
  }
  
  .header-text h3 {
    margin: 0 0 4px 0;
    font-size: 18px;
    font-weight: 600;
  }
  
  .header-text p {
    margin: 0;
    color: #666;
    font-size: 14px;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    margin-bottom: 20px;
  }
  
  .feature-item {
    background: white;
    padding: 16px;
    border-radius: 8px;
    text-align: center;
  }
  
  .feature-icon {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 12px;
  }
  
  .feature-icon.blue {
    background: #e6f3ff;
    color: #0066ff;
  }
  
  .feature-icon.green {
    background: #e6fff0;
    color: #00cc66;
  }
  
  .feature-item p {
    margin: 0;
    font-size: 14px;
    color: #333;
  }
  
  .start-button {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 6px;
    color: white;
    font-weight: 500;
    cursor: pointer;
    transition: opacity 0.2s;
  }
  
  .start-button.blue {
    background: #0066ff;
  }
  
  .start-button.green {
    background: #00cc66;
  }
  
  .start-button:hover {
    opacity: 0.9;
  }
  
  @media (max-width: 768px) {
    .features-grid {
      grid-template-columns: 1fr;
    }
    
    .event-selection-popup {
      width: 95%;
      padding: 16px;
    }
  }

  .channels {
    margin-top: 1rem;
    font-size: 0.9rem;
  }
  
  .channels strong {
    display: block;
    margin-top: 0.5rem;
  }
  
  .channels p {
    margin: 0.25rem 0;
    word-break: break-word;
  }