.privacy-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .privacy-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 2px solid #eee;
  }
  
  .privacy-logo {
    width: 150px;
    cursor: pointer;
  }
  
  .privacy-content {
    padding: 20px 0;
  }
  
  .privacy-content h1 {
    font-size: 32px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .privacy-content h2 {
    font-size: 24px;
    margin-top: 20px;
    color: #333;
  }
  
  .privacy-content p {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
  }
  
  .privacy-content ul {
    padding-left: 20px;
    list-style-type: disc;
  }
  
  .privacy-content ul li {
    margin-bottom: 10px;
  }
  
  .privacy-content strong {
    color: #000;
  }
  