/* Main container */
.event-table-container {
  min-height: calc(100vh - 70px);
  margin-left: 250px;
  padding: 20px;
  padding-top: 90px;
  background: #ffffff;
  transition: all 0.3s ease;
  width: calc(100% - 250px);
}

/* Header styles */
.event-table-header {
  background-color: #ffe092;
  padding: 20px;
  margin: 0 0 20px 0;
  border-radius: 0;
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
}

.event-table-breadcrumb {
  font-size: var(--text-sm);
  color: #000;
  display: flex;
  align-items: center;
  gap: 8px;
}

.event-table-breadcrumb .separator {
  margin: 0 8px;
}

/* Content area */
.event-table-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.event-table-content h2 {
  font-family: 'Public Sans', sans-serif;
  color: #333;
  margin-bottom: 10px;
}

.event-table-content p {
  font-family: 'Public Sans', sans-serif;
  color: #000000;
  margin-bottom: 20px;
}

/* Grid layout for platforms */
.event-table-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-bottom: 30px;
}

.platform-section {
  width: 100%;
}

.platform-section h3 {
  font-family: 'Public Sans', sans-serif;
  padding: 10px 0;
  font-size: 18px;
  margin-bottom: 10px;
}

/* Table styles */
.table-wrapper {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  min-width: 300px;
}

th, td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  font-size: 14px;
  font-family: 'Public Sans', sans-serif;
}

th {
  background-color: #f2f2f2;
  font-weight: 600;
}

/* Input styles */
input[type="number"] {
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: var(--text-sm);
}

/* Summary section */
.event-table-summary {
  background-color: #f0f0f0;
  padding: 20px;
  margin: 30px 0;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Action buttons */
.event-table-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.btn-back,
.btn-next {
  padding: 10px 30px;
  font-size: 14px;
  border-radius: 6px;
  cursor: pointer;
  height: 40px;
  background-color: #FFB800;
  color: #000;
  border: none;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.btn-back:hover,
.btn-next:hover {
  background-color: #FF8C00;
}

/* Responsive adjustments */
@media screen and (max-width: 1200px) {
  .event-table-container {
    margin-left: 200px;
    width: calc(100% - 200px);
  }
}

@media screen and (max-width: 992px) {
  .event-table-container {
    margin-left: 180px;
    width: calc(100% - 180px);
  }
  
  .event-table-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

@media screen and (max-width: 768px) {
  .event-table-container {
    margin-left: 0;
    width: 100%;
    padding: 15px;
    padding-top: 80px;
  }

  .event-table-header {
    height: auto;
    padding: 15px;
  }

  .event-table-content {
    padding: 0 10px;
  }

  .event-table-grid {
    gap: 15px;
  }

  .event-table-summary {
    padding: 15px;
  }

  .event-table-actions {
    flex-direction: row;
    gap: 15px;
  }

  .btn-back,
  .btn-next {
    flex: 1;
  }
}