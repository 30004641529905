
.main-container {
  display: flex;
  height: 100vh;
  background-color: #F0AB00;
  font-family: 'Nunito Sans', sans-serif;
}
.branding-wrapper {
  flex: 1;
  padding: 60px;
  color: black;
  background-color: #F0AB00;
  flex-direction: column;
  justify-content: space-between;
}
.branding-title {
  font-size: 5rem;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
.branding-subtitle {
  font-size: 1.5rem;
  margin: 20px 0;
}
.image-section {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 20px 0;
}
.image-thumbnail {
  width: 80%;
  height: 80%;
  border-radius: 10%;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}
.branding-footer {
  margin-top: auto;
  font-size: 1rem;
}
.form-wrapper {
  flex: 1;
  padding: 10px;
  background: linear-gradient(400deg, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF,#FFFFFF,#C89EFF) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  margin: 30px;
  margin-left: 3%;
  margin-right: 3%;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}
.form-header {
  display: flex;
  gap: 15px;
  background-color: #F2E9FF;
  padding: 4px 10px 4px 10px;
  border: 2px solid #b884fd9f;
  border-radius: 10px;
  margin-bottom: 30px;
}
.header-button {
  background: #ffffff00;
  padding: 12px 25px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Public Sans', sans-serif;
  color: #9747FF;
  transition: background 0.3s, color 0.3s;
}
.header-button:hover {
  background: #9747FF;
  color: #FFFFFF;
}
.active-button {
  background: #FFFFFF;
  color: #9747FF;
}
.input-text {
  padding: 10px;
  border: 20px;
  border-bottom: 1px solid rgb(56, 56, 56);
  font-size: 14px;
  font-family: 'Public Sans', sans-serif;
  color: #000;
  background-color: #FFFFFF;
  width: 95%;
  box-sizing: border-box;
  transition: border-color 0.3s;
}
.input-text:focus {
  border-color: #9C27B0;
  outline: none;
}
.form-submit-button {
  padding: 15px;
  background-color: #9747FF;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500px;
  width: 100%;
  margin-top: 20px;
  transition: background 0.3s;
}
.form-submit-button:hover {
  background-color: #9747FF;
}

.form-submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.form-toggle-text {
  text-align: center;
  margin-top: 20px;
  font-size: 1rem;
}
.toggle-link-text {
  color: #0CBE27;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
}
.logo-container {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
}
.logo {
  width: 120px; /* Adjust the size as needed */
  height: auto;
}
.form-wrapper {
  position: relative;
  flex: 1;
  padding: 10px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 2% 5% 2% 5%;
}
.video-section {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 20px 0;
}
.video-thumbnail {
  width: 100%;
  height: 100%;
  border-radius: 10%;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}
.branding-wrapper {
  text-align: left;
  margin-bottom: 0px;
}
.branding-image {
  max-width: 30%;
  height: auto;
  display: block;
}
/* Add media queries for mobile responsiveness */
@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    height: auto;
  }
  .branding-wrapper, .form-wrapper {
    flex: none;
    width: 100%;
    margin: 10px 0;
    padding: 20px;
  }
  .branding-wrapper {
    padding: 20px 10px;
    text-align: center;
  }
  .branding-image {
    max-width: 50%;
    margin: 0 auto 20px;
  }
  .branding-subtitle {
    font-size: 1.2rem;
  }
  .branding-footer {
    font-size: 0.9rem;
    margin-top: 10px;
  }
  .video-section {
    margin: 20px 0;
  }
  .video-thumbnail {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  .form-wrapper {
    padding: 15px;
    margin: 20px;
    width: calc(100% - 40px);
  }
  .form-header {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    margin-top: 40%;
  }
  .header-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }
  .input-text {
    width: 100%;
  }
  .form-submit-button {
    width: 100%;
  }
  .logo-container {
    top: 10px;
    left: 10px;
    padding: 5px;
  }
  .logo {
    width: 100px;
  }
}


/* New styles for the checkbox and its label */
.terms-checkbox {
  display: flex;
  align-items: center;
  margin-top: 15px;
  width: 35%;
  white-space: nowrap;
}

.terms-checkbox input[type="checkbox"] {
  margin-right: 10px;
  cursor: pointer;
}

.terms-checkbox label {
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.terms-checkbox a {
  color: #9747FF;
  text-decoration: none;
}

.terms-checkbox a:hover {
  text-decoration: underline;
}
