/* ProfileForm.css */

.profile-form-container {
  display: flex;
  max-width: 900px;
  margin: 0 auto;
  margin-top: 8%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
}
.side-menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 20px;
}

.side-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.side-button i {
  margin-right: 8px;
}

.side-button.active {
  background-color: #007bff;
  color: #fff;
}

.side-button:hover {
  background-color: #e0e0e0;
}

.profile-form-container {
  display: flex;
  padding: 20px;
}

.profile-card, .password-card, .invite-card {
  width: 100%;
  padding: 20px;
  background-color: #F7F7F7;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-field {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn-primary {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-primary:hover {
  background-color: #0056b3;
}

h2 {
  text-align: left;
  color: #333;
  margin-bottom: 15px;
  font-family: 'Public Sans', sans-serif;
  font-size: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: 600px;
  color: #444;
  margin-bottom: 8px;
  font-family: 'Public Sans', sans-serif;
}

.input-field {
  width: 90%;
  padding: 12px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.input-field:focus {
  border-color: #1d4ed8;
  outline: none;
  box-shadow: 0 0 8px rgba(29, 78, 216, 0.3);
}

button {
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  font-family: 'Public Sans', sans-serif;
  font-weight: 500px;
}

.btn-primary {
  background-color: #1d4ed8;
  color: white;
}

.btn-primary:hover {
  background-color: #2563eb;
  box-shadow: 0 4px 12px rgba(37, 99, 235, 0.2);
}

@media (max-width: 600px) {
  .profile-form-container {
      flex-direction: column;
      padding: 15px;
    display: flex;
    max-width: 900px;
    margin: 0 auto;
    margin-top: 8%;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  }
}
  
  .side-menu {
    flex: 0 0 200px;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    background-color: #F7F7F7;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    height: 50%;
    padding: 20px;
    border-radius: 10px;
  }
  
  .side-button {
    padding: 15px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    margin-bottom: 10px;
    background-color: #F65CF8;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    font-family: 'Public Sans', sans-serif;
    font-weight: 600;
  }
  
  .side-button.active {
    background-color: #db46dd;
  }
  
  .side-button:hover {
    background-color: #db46dd;
    box-shadow: 0 4px 12px rgba(59, 130, 246, 0.2);
  }
  
  .profile-card, .password-card {
    flex: 1;
    background-color: #F7F7F7;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    padding: 20px;
    border-radius: 10px;
  }
  
  h2 {
    text-align: left;
    color: #333;
    margin-bottom: 15px;
    font-family: 'Public Sans', sans-serif;
    font-size: 20px;
  }
  
  .form-group {
    margin-bottom: 5px;
  }
  
  label {
    display: block;
    font-weight: bold;
    color: #444;
    margin-bottom: 8px;
  }
  
  .input-field {
    width: 90%;
    padding: 12px;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    font-size: 14px;
    transition: border-color 0.2s, box-shadow 0.2s;
  }
  
  .input-field:focus {
    border-color: #1d4ed8;
    outline: none;
    box-shadow: 0 0 8px rgba(29, 78, 216, 0.3);
  }
  
  button {
    padding: 10px 16px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    font-family: 'Public Sans', sans-serif;
    font-weight: bold;
  }
  
  .btn-primary {
    background-color: #81FF27;
    margin-top: 20px;
    color: rgb(20, 20, 20);
  }
  
  .btn-primary:hover {
    background-color: #73ec1d;
    box-shadow: 0 4px 12px rgba(37, 99, 235, 0.2);
  }
  
  @media (max-width: 600px) {
    .profile-form-container {
        flex-direction: column;
        padding: 15px;
    }
  
    h2 {
        font-size: 1.5em;
    }
  
    .side-menu {
        flex-direction: row;
        margin-bottom: 20px;
    }
  
    .side-button {
        flex: 1;
        margin-right: 10px;
        font-family: 'Public Sans', sans-serif;
    }
  
    button {
        font-size: 14px;
        padding: 10px 15px;
    }
  }

@media (max-width: 600px) {
    .invite-card {
        padding: 15px;
    }
}

