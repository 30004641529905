.comparison-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 20px;
  }
  
  .comparison-modal {
    background: white;
    border-radius: 8px;
    width: 100%;
    max-width: 1200px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .comparison-header {
    padding: 20px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .comparison-header h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 5px;
    line-height: 1;
  }
  
  .comparison-content {
    padding: 20px;
  }
  
  .metrics-comparison, .channels-comparison {
    margin-bottom: 30px;
  }
  
  .metrics-comparison h3, .channels-comparison h3 {
    color: #333;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 2px solid #eee;
  }
  
  .comparison-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .metric-block {
    background: #f8f9fa;
    padding: 15px;
    border-radius: 8px;
  }
  
  .metric-block.highlight {
    background: #f0f7ff;
    border: 1px solid #cce5ff;
  }
  
  .metric-block h4 {
    margin: 0 0 15px 0;
    color: #555;
    font-size: 1rem;
  }
  
  .comparison-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
  
  .pre, .post {
    display: flex;
    flex-direction: column;
  }
  
  .pre label, .post label {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 5px;
  }
  
  .pre span, .post span {
    font-size: 1.1rem;
    font-weight: 600;
    color: #333;
  }
  
  .highlight-value {
    color: #2196f3 !important;
  }
  
  .channels-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .channel-block {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
  }
  
  .channel-block h4 {
    margin: 0 0 15px 0;
    color: #555;
  }
  
  .channel-lists {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .online-channels, .offline-channels {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .online-channels label, .offline-channels label {
    font-size: 0.9rem;
    color: #666;
  }
  
  .channel-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .channel-tag {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.9rem;
  }
  
  .channel-tag.online {
    background: #e3f2fd;
    color: #1976d2;
  }
  
  .channel-tag.offline {
    background: #f3e5f5;
    color: #7b1fa2;
  }
  
  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    font-size: 1.1rem;
    color: #666;
  }
  
  @media (max-width: 768px) {
    .channels-grid {
      grid-template-columns: 1fr;
    }
  
    .comparison-row {
      grid-template-columns: 1fr;
    }
  }