/* Overlay & Modal Base */
.event-popup-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: 1rem;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.event-popup-overlay.visible {
  opacity: 1;
}

.event-popup-modal {
  background: white;
  width: 95%;
  max-width: 1200px;
  max-height: 90vh;
  margin: auto;
  border-radius: 8px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transform: scale(0.95);
  opacity: 0;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.event-popup-overlay.visible .event-popup-modal {
  transform: scale(1);
  opacity: 1;
}

/* Enhanced Loading States */
.loading-modal {
  max-width: 400px;
  height: 400px;
  padding: 2rem;
  transform: none !important;
}

.event-popup-loading {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  background: white;
  border-radius: 8px;
}

.event-popup-spinner {
  width: 50px;
  height: 50px;
  border: 4px solid #f3f4f6;
  border-top: 4px solid #4f46e5;
  border-radius: 50%;
  animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  margin-bottom: 1.5rem;
}

.loading-message {
  margin: 1rem 0;
  color: #6b7280;
  font-size: 1rem;
  font-weight: 500;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.loading-progress {
  width: 100%;
  height: 4px;
  background: #f3f4f6;
  border-radius: 2px;
  margin-top: 1rem;
  overflow: hidden;
}

.loading-bar {
  height: 100%;
  background: #4f46e5;
  border-radius: 2px;
  transition: width 0.5s ease-in-out;
  position: relative;
}

.loading-bar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  animation: shimmer 1.5s infinite;
}

/* Header */
.event-popup-header {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e7eb;
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.event-popup-header h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

.event-popup-close {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  background: #f3f4f6;
  color: #6b7280;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
}

.event-popup-close:hover {
  background: #e5e7eb;
  color: #111827;
}

/* Tabs */
.event-popup-tabs {
  display: flex;
  gap: 0.5rem;
  padding: 0 1.5rem;
  border-bottom: 1px solid #e5e7eb;
  background: white;
  position: sticky;
  top: 64px;
  z-index: 9;
}

.event-popup-tab {
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #6b7280;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  transition: all 0.2s;
}

.event-popup-tab:hover {
  color: #4f46e5;
}

.event-popup-tab.active {
  color: #4f46e5;
}

.event-popup-tab.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 2px;
  background: #4f46e5;
}

/* Content Area */
.event-popup-content {
  flex: 1;
  overflow-y: auto;
  padding: 1.5rem;
  background: #f9fafb;
}

/* Overview Tab */
.event-popup-overview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 1.5rem;
}

.event-popup-card {
  background: white;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  overflow: hidden;
}

.event-popup-card-header {
  padding: 1rem 1.25rem;
  background: #f8fafc;
  border-bottom: 1px solid #e5e7eb;
}

.event-popup-card-header h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: #111827;
}

.event-popup-card-body {
  padding: 1.25rem;
}

.event-popup-field {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0;
  border-bottom: 1px solid #f3f4f6;
}

.event-popup-field:last-child {
  border-bottom: none;
}

.event-popup-field span:first-child {
  color: #6b7280;
  font-size: 0.875rem;
}

.event-popup-field span:last-child {
  font-weight: 500;
  color: #111827;
  font-size: 0.875rem;
}

.event-popup-field.highlight span:last-child {
  color: #4f46e5;
  font-weight: 600;
}

/* Metrics Tab */
.event-popup-metrics {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.event-popup-chart-section {
  background: white;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  padding: 1.5rem;
}

.event-popup-chart-section h3 {
  margin: 0 0 1.5rem 0;
  font-size: 1rem;
  font-weight: 600;
  color: #111827;
}

.event-popup-chart {
  width: 100%;
  height: 400px;
}

/* Metrics Cards */
.event-comparison-metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 1.5rem;
}

.event-comparison-metrics-card {
  background: white;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  overflow: hidden;
}

.event-comparison-metrics-header {
  padding: 1rem 1.25rem;
  background: #f8fafc;
  border-bottom: 1px solid #e5e7eb;
}

.event-comparison-metrics-header h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: #111827;
}

.event-comparison-type {
  display: block;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #6b7280;
}

.event-comparison-metrics-content {
  padding: 1.25rem;
}

.event-comparison-metric-group {
  margin-bottom: 1.5rem;
}

.event-comparison-metric-group:last-child {
  margin-bottom: 0;
}

.event-comparison-metric-group h4 {
  font-size: 0.875rem;
  font-weight: 600;
  color: #374151;
  margin: 0 0 1rem 0;
}

.event-comparison-metric-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.event-comparison-metric-item {
  background: #f8fafc;
  padding: 1rem;
  border-radius: 6px;
}

.event-comparison-metric-item label {
  display: block;
  font-size: 0.75rem;
  color: #6b7280;
  margin-bottom: 0.25rem;
}

.event-comparison-value {
  font-size: 0.875rem;
  font-weight: 600;
  color: #111827;
}

/* Marketing Channels */
.event-comparison-marketing {
  margin-top: 1.5rem;
}

.event-comparison-marketing h4 {
  font-size: 0.875rem;
  font-weight: 600;
  color: #374151;
  margin: 0 0 1rem 0;
}

.event-comparison-channels {
  display: grid;
  gap: 1rem;
}

.event-comparison-channel-group label {
  display: block;
  font-size: 0.75rem;
  color: #6b7280;
  margin-bottom: 0.5rem;
}

.event-comparison-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.event-comparison-tag {
  font-size: 0.75rem;
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  background: #f3f4f6;
  color: #374151;
}

.event-comparison-tag-online {
  background: #e0f2fe;
  color: #0369a1;
}

/* Loading State */
.event-popup-loading {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  z-index: 20;
}

.event-popup-spinner {
  border: 3px solid #f3f4f6;
  border-top: 3px solid #4f46e5;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: .5; }
}

@keyframes shimmer {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}


/* Insights Tab */
.event-popup-insights {
  background: white;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  padding: 1.5rem;
}

.event-popup-insights-content {
  font-size: 0.875rem;
  line-height: 1.6;
  color: #374151;
}

.event-popup-insights-content p {
  margin: 0 0 1rem 0;
}

.event-popup-insights-content p:last-child {
  margin-bottom: 0;
}

@media (max-width: 1024px) {
  .event-popup-overview,
  .event-comparison-metrics-grid {
    grid-template-columns: 1fr;
  }

  .event-popup-modal {
    width: 100%;
    height: 80vh;
    max-height: 80vh;
    margin: 0;
    border-radius: 0;
  }

  .loading-modal {
    width: 90%;
    max-width: 350px;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .event-popup-overlay {
    padding: 0;
  }

  .event-popup-header {
    padding: 0.875rem;
  }

  .event-popup-header h2 {
    font-size: 1.125rem;
  }

  .event-popup-tabs {
    padding: 0 0.875rem;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .event-popup-tab {
    padding: 0.625rem 0.875rem;
    white-space: nowrap;
  }

  .event-popup-content {
    padding: 0.875rem;
  }

  .event-popup-chart {
    height: 300px;
  }

  .event-comparison-metric-row {
    grid-template-columns: 1fr;
  }

  .event-popup-card,
  .event-comparison-metrics-card {
    min-width: unset;
  }

  .event-popup-field {
    flex-direction: column;
    gap: 0.25rem;
  }

  .event-popup-field span:last-child {
    font-size: 0.9375rem;
  }

  .loading-modal {
    width: 85%;
    padding: 1.5rem;
  }

  .event-popup-spinner {
    width: 40px;
    height: 40px;
  }

  .loading-message {
    font-size: 0.9375rem;
  }
}

@media (max-width: 480px) {
  .event-popup-card,
  .event-comparison-metrics-card {
    margin: 0.5rem 0;
  }

  .event-popup-chart-section {
    padding: 1rem;
  }

  .event-popup-chart {
    height: 250px;
  }

  .loading-modal {
    width: 95%;
    padding: 1rem;
  }

  .event-comparison-tags {
    flex-wrap: wrap;
  }

  .event-comparison-tag {
    font-size: 0.6875rem;
    padding: 0.1875rem 0.5rem;
  }
}

/* Improved Touch Interactions for Mobile */
@media (hover: none) {
  .event-popup-tab:hover {
    color: inherit;
  }

  .event-popup-close:hover {
    background: #f3f4f6;
    color: #6b7280;
  }
}

/* Additional Accessibility Improvements */
@media (prefers-reduced-motion: reduce) {
  .event-popup-overlay,
  .event-popup-modal,
  .loading-bar,
  .event-popup-spinner {
    transition: none;
    animation: none;
  }
}