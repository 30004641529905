/* EventForm Container */
.pre-event-form-container {
  min-height: calc(100vh - 70px);
  margin-left: 250px;
  padding: 20px;
  padding-top: 90px;
  background: #ffffff;
  transition: all 0.3s ease;
  width: calc(100% - 250px);
}

/* Form Header with Fixed Height and Padding */
.event-form-header {
  background-color: #ffe092;
  padding: 20px;
  margin: 0px 0px 20px 0px;
  border-radius: 0;
  height: 40px; /* Fixed height */
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
}

.event-breadcrumb {
  font-size: var(--text-sm);
  color: #000;
  display: flex;
  align-items: center;
  gap: 8px;
}

.event-breadcrumb .separator {
  margin: 0 8px;
}

/* Form Layout with Fixed Widths */
.event-form-container form {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.event-form-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px; /* Increased gap for better spacing */
  margin-bottom: 25px;
}

.event-form-group {
  width: 100%;
}

.event-form-group.full-width {
  grid-column: 1 / -1;
}

/* Form Elements with Consistent Sizing */
.event-form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: var(--font-medium);
  font-size: var(--text-sm);
  color: #333;
}

.required {
  color: #ff0000;
  margin-left: 2px;
}

/* Consistent Input/Select Styling */
.event-form-group input,
.event-form-group select {
  width: 100%;
  height: 40px; /* Fixed height for all inputs */
  padding: 8px 12px;
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: var(--text-sm);
  color: #333;
  box-sizing: border-box;
}

/* Platform Section Improvements */
.platforms-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-top: 15px;
}

.platform-group {
  width: 100%;
}

.platform-group h4 {
  margin-bottom: 15px;
  font-size: var(--text-base);
  font-weight: var(--font-medium);
  color: #333;
}

.platform-options {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.platform-options button {
  width: 100%;
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: var(--text-sm);
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.platform-options button.active {
  background-color: #4CAF50;
  color: white;
  border-color: #4CAF50;
}

/* Form Actions */
.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.btn-next {
  background-color: #FFB800;
  color: #000;
  padding: 10px 30px;
  border: none;
  border-radius: 6px;
  font-size: var(--text-sm);
  font-weight: var(--font-medium);
  cursor: pointer;
  transition: all 0.2s ease;
  height: 40px; /* Consistent height */
}

/* Responsive Adjustments */
@media screen and (max-width: 1200px) {
  .pre-event-form-container {
    margin-left: 200px;
    width: calc(100% - 200px);
  }
  
  .event-form-row {
    gap: 20px;
  }
}

@media screen and (max-width: 992px) {
  .pre-event-form-container {
    margin-left: 180px;
    width: calc(100% - 180px);
  }

  .event-form-row {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}

@media screen and (max-width: 768px) {
  .pre-event-form-container {
    margin-left: 0;
    width: 100%;
    padding: 15px;
    padding-top: 80px;
  }

  .event-form-header {
    height: auto;
    padding: 15px;
  }

  .event-breadcrumb {
    padding-top: 10px;
  }

  .event-form-row {
    grid-template-columns: 1fr;
  }

  .platforms-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

/* Additional field-specific styling */
.event-form-group input[type="date"] {
  width: 100%;
  height: 40px;
  padding: 8px 12px;
}

.event-form-group input:disabled,
.event-form-group select:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Error message styling */
.error-message {
  background-color: #ffebee;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 20px;
  text-align: center;
  border: 1px solid #ef5350;
}

.error-text {
  color: #d32f2f;
  font-weight: var(--font-medium);
  font-size: var(--text-sm);
}

/* Toggle Switch Styles */
.event-toggle-switch {
  display: inline-flex;
  align-items: center;
  gap: 4px; /* Small gap between toggle and text */
}

.toggle-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #4CAF50; /* Green for active */
  border-radius: 20px;
  width: 64px;
  height: 32px;
  padding: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-button:not(.active) {
  background: #e0e0e0; /* Gray for inactive */

}

.toggle-slider {
  position: absolute;
  background: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  left: 4px;
  transition: transform 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.toggle-button.active .toggle-slider {
  transform: translateX(32px);
}

.toggle-labels {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  color: white;
  font-size: 12px;
  font-weight: 500;
}

.label-yes,
.label-no {
  opacity: 0.9;
  user-select: none;
}



:not(.active) .label-yes {
  opacity: 0;
}

:not(.active) .label-no {
  opacity: 1;
}

.active .label-yes {
  opacity: 1;
}

.active .label-no {
  opacity: 1;
}