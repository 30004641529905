.delete-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .delete-popup-content {
    background: white;
    padding: 24px;
    border-radius: 12px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  }
  
  .delete-popup-content h3 {
    margin: 0 0 16px;
    color: #1A1A1A;
    font-size: 20px;
    font-weight: 600;
  }
  
  .delete-popup-content p {
    margin: 0 0 20px;
    color: #666;
    font-size: 14px;
    line-height: 1.5;
  }
  
  .phase-selection {
    margin-bottom: 24px;
  }
  
  .phase-selection p {
    margin-bottom: 12px;
    font-weight: 500;
  }
  
  .checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .checkbox-group label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    font-size: 14px;
    color: #333;
  }
  
  .checkbox-group input[type="checkbox"] {
    width: 16px;
    height: 16px;
  }
  
  .delete-popup-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
  }
  
  .cancel-button, .delete-button {
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .cancel-button {
    background: #f5f5f5;
    border: 1px solid #ddd;
    color: #666;
  }
  
  .delete-button {
    background: #dc3545;
    border: none;
    color: white;
  }
  
  .cancel-button:hover:not(:disabled) {
    background: #eee;
  }
  
  .delete-button:hover:not(:disabled) {
    background: #c82333;
  }
  
  .cancel-button:disabled,
  .delete-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  /* Success state */
  .delete-popup-content.success {
    text-align: center;
    padding: 32px 24px;
  }
  
  .success-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  
  .success-message i {
    font-size: 48px;
    color: #28a745;
  }
  
  .success-message h3 {
    color: #28a745;
    margin: 0;
  }
  
  .success-message p {
    margin: 0;
    color: #666;
  }
  
  /* Spinner animation */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .fa-spinner {
    animation: spin 1s linear infinite;
  }